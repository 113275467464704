import React, {useState} from 'react';
import classNames from 'classnames';
import MapMobileModal from '../mobileMapModal';
import {useLanguage} from '@/hooks/LanguageContext';

const Accordion = ({active, store, onClick}) => {
  const {translate} = useLanguage();
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(false);

  const handleShowMap = () => {
    setIsMapVisible(true);
    onClick(store?.id);
  };

  const handleCloseMap = () => {
    setIsMapVisible(false);
    onClick(null);
  };

  return (
    <div className="border-b-[0.5px] border-[#BBB] xl:hidden">
      <div onClick={() => setOpenAccordion(prevState => !prevState)}>
        <div
          className={classNames('px-[48px] py-[20px] max-lg:px-[16px] max-lg:py-[16px] flex justify-between items-center w-full transition-all', {
            '!bg-[#F8F6F7]': active === store?.id,
          })}>
          <div className="text-[16px] text-primary">
            <h4 className="font-[540]">{store?.title}</h4>
            <p className="mt-[4px] text-[12px] text-primary">{store?.address}</p>
          </div>
          <button
            className={classNames('duration-300 rotate-90', {
              'duration-300 -rotate-90 transition': openAccordion,
            })}>
            <i className="icon right-chevron text-primary text-[20px]" />
          </button>
        </div>
        {openAccordion && (
          <div className="mx-4 lg:mx-12">
            {store?.hours && (
              <div className="flex flex-col">
                <h6 className="text-[12px] font-boldie leading-4 tracking-[0.24px] text-primary">{translate('Work Hours')}</h6>
                <p className="flex items-center text-[14px] leading-5 tracking-[0.28px] text-primary">{store?.hours}</p>
              </div>
            )}
            {store?.items && (
              <div className="flex flex-col gap-12 mt-12">
                {store?.items?.map((item, index) => (
                  <div key={index} className="flex flex-col gap-3">
                    <h6 className="font-boldie text-[14px] leading-5 tracking-[0.28px] uppercase text-[#999]">{item?.title}</h6>
                    {item?.values?.map((value, index) => (
                      <p key={index} className="leading-[22px] tracking-[0.32px]">
                        {value?.icon && <i className={`icon ${value?.icon} mr-2`}></i>}
                        {value?.name}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            )}
            <div className="flex items-center pt-4 pb-6">
              <button
                className="border-[1px] border-primary py-[10px] px-3 text-primary text-[14px] font-boldie leading-5 tracking-[0.28px]"
                onClick={handleShowMap}>
                {translate('Show on Map')}
              </button>
              <a href={`tel:${store?.phone}`} className="border-[1px] border-primary ml-[10px] px-[10px] pt-[10px] pb-[6px]">
                <i className="icon telephone text-[20px] text-primary" />
              </a>
            </div>
          </div>
        )}
      </div>
      {isMapVisible && <MapMobileModal store={store} active={active} handleCloseMap={handleCloseMap} />}
    </div>
  );
};

export default Accordion;
