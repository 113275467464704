import Link from 'next/link';
import {useLanguage} from '@/hooks/LanguageContext';

const MapModal = ({data, onClick}) => {
  const {translate} = useLanguage();
  return (
    <div className="absolute top-0 left-0 bg-white w-full h-full px-[48px] py-[32px] max-lg:px-[16px]  max-lg:py-[0]">
      <div className="flex flex-row items-start w-full justify-between">
        <h2 className="text-[32px] font-boldie leading-[44px] tracking-[0.64px] pr-[15px]  max-lg:text-[24px]">{data?.title}</h2>
        <button className="mt-[16px] max-lg:mt-[10px]" onClick={() => onClick(null)}>
          <i className="icon close text-[24px] text-2xl text-primary"></i>
        </button>
      </div>
      <p className="text-[16px] leading-[22px] tracking-[0.32px] mt-8">{data?.address}</p>
      {data?.phone && (
        <p className="flex items-center mt-3">
          <i className="icon telephone mr-2 text-[#515151]" />
          {data?.phone}
        </p>
      )}
      <Link
        href={`https://www.google.com/maps/dir/?api=1&destination=${data.locations.join(',')}`}
        target="_blank"
        className="leading-[22px] font-boldie tracking-[0.32px] mt-5 block">
        <i className="icon location mr-2" />
        {translate('Directions')}
      </Link>
      {data?.hours && (
        <div className="flex flex-col">
          <h6 className="text-[14px] font-boldie leading-5 tracking-[0.28px] uppercase text-[#999] pt-12">{translate('Work Hours')}</h6>
          <p className="flex items-center mt-3 text-[16px] leading-[22px] tracking-[0.32px] text-primary">{data?.hours}</p>
        </div>
      )}
      <div className="flex flex-col gap-12 mt-12">
        {data?.items?.map((item, index) => (
          <div key={index} className="flex flex-col gap-3">
            <h6 className="font-boldie text-[14px] leading-5 tracking-[0.28px] uppercase text-[#999]">{item?.title}</h6>
            {item?.values?.map((value, index) => (
              <p key={index} className="leading-[22px] tracking-[0.32px]">
                {value?.icon && <i className={`icon ${value?.icon} mr-2`}></i>}
                {value?.name}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MapModal;
