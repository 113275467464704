import Link from 'next/link';
import Map from '../map';
import {fileDomain} from '@/config/constants';
import {useLanguage} from '@/hooks/LanguageContext';

const MapMobileModal = ({store, active, handleCloseMap}) => {
  const {translate} = useLanguage();

  return (
    <div className="absolute -top-[64px] left-0 bg-white w-full h-screen py-[0] z-20">
      <div className="flex items-center py-3 pl-4">
        <button className="h-[26px]" onClick={handleCloseMap}>
          <i className="icon close text-[26px] text-primary" />
        </button>
        <p className="flex-auto text-primary font-medium text-[17px] leading-[22px] tracking-[0.34px] text-center">{store?.title}</p>
      </div>
      <Map
        center={{lat: store?.locations[0], lng: store?.locations[1]}}
        zoom={16}
        data={[store]}
        active={active}
        icon={`${fileDomain}/public/boyner-corporate/customised-pin-map-boyner.png`}
      />
      <div className="absolute top-[600px] left-4 z-50">
        <Link
          href={`https://www.google.com/maps/dir/?api=1&destination=${store?.locations.join(',')}`}
          target="_blank"
          className=" bg-white p-3 leading-[22px] font-boldie tracking-[0.32px] block">
          <i className="icon location mr-2" />
          {translate('Directions')}
        </Link>
      </div>
      <div className="flex flex-col gap-2 text-primary pt-[14px] px-4 h-[142px]">
        <h5 className="text-[16px] font-boldie leading-[22px] tracking-[0.32px]">{store?.title}</h5>
        <div className="flex items-start gap-2">
          <i className="icon address text-[16px]" />
          <p className="text-[12px] leading-4 tracking-[0.24px] font-normal">{store?.address}</p>
        </div>
        {store?.hours && (
          <div className="flex items-start gap-2">
            <i className="icon clock text-[16px]" />
            <p className="text-[12px] leading-4 tracking-[0.24px] font-normal">{store?.hours}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MapMobileModal;
