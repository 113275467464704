import {MarkerClusterer} from '@googlemaps/markerclusterer';
import {Wrapper, Status} from '@googlemaps/react-wrapper';
import {useCallback, useEffect, useRef} from 'react';
import {fileDomain} from '@/config/constants';

const render = (status, props) => {
  switch (status) {
    case Status.LOADING:
      return <div>loading</div>;
    case Status.FAILURE:
      return <div>Error</div>;
    case Status.SUCCESS:
      return <MapComponent {...props} />;
  }
};

const MapWrapper = props => <Wrapper apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY} render={status => render(status, props)} />;

const MapComponent = ({
  center = {lat: 38.9825143, lng: 35.6485848},
  zoom = 6,
  data = [],
  onClick,
  icon = `${fileDomain}/public/boyner-corporate/mapMarker.png`,
}) => {
  const ref = useRef();
  const map = useRef();

  const mapHandleCenter = useCallback(
    (location, cZoom = zoom) => {
      const initialLocation = new window.google.maps.LatLng({lat: location.lat, lng: location.lng});
      map.current.setCenter(initialLocation);
      map.current.setZoom(cZoom);
    },
    [zoom],
  );

  const markerOnClick = useCallback(
    marker => {
      const filteredData = data.find(item => item.id === marker.id);
      mapHandleCenter({lat: filteredData?.locations[0], lng: filteredData?.locations[1]}, 17);
      onClick(marker.id);
    },
    [data, mapHandleCenter, onClick],
  );

  useEffect(() => {
    if (map.current) {
      mapHandleCenter(center, zoom);
    }
  }, [center]);

  useEffect(() => {
    map.current = new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });

    const markers = data.map(({locations, id}) => {
      const marker = new window.google.maps.Marker({
        position: {lat: locations[0], lng: locations[1]},
        icon: icon,
        id: id,
      });
      window.google.maps.event.addListener(marker, 'click', () => markerOnClick(marker));
      return marker;
    });

    const renderer = {
      render: ({count, position}) =>
        new window.google.maps.Marker({
          label: {text: String(count), color: 'white', fontSize: '14px', top: '-6px'},
          position,
          icon: `${fileDomain}/public/boyner-corporate/mapBigMarker.png`,
          zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
        }),
    };

    const markerCluster = new MarkerClusterer({markers, renderer});
    markerCluster.setMap(map.current);
  }, [data]);

  return <div ref={ref} id="map" className="xl:h-min-[665px] xl:h-[calc(100vh-88px)] h-[620px] xl:flex-1 z-20" />;
};

const Map = props => <MapWrapper {...props} />;

export default Map;
