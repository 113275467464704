import {useMemo} from 'react';

const useTextNormalization = () => {
  const normalizeText = text => {
    if (!text) return ''; // return empty if it's empty

    const normalizedText = text
      .toLocaleLowerCase('tr')
      .replace(/ğ/g, 'g')
      .replace(/ü/g, 'u')
      .replace(/ş/g, 's')
      .replace(/ı/g, 'i')
      .replace(/ö/g, 'o')
      .replace(/ç/g, 'c');

    const finalText = normalizedText.replace(/İ/g, 'i').replace(/[^\w\s]/g, '');

    return finalText;
  };

  return useMemo(() => normalizeText, []);
};

export default useTextNormalization;
