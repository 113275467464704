import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import useTextNormalization from '@/hooks/useTextNormalization';
import {useRouter} from 'next/router';
import {useLanguage} from '@/hooks/LanguageContext';
import Link from 'next/link';

const ListOfCities = ({mapData, city, active}) => {
  const {translate} = useLanguage();
  const normalizeText = useTextNormalization();
  const router = useRouter();

  const [selectedCity, setSelectedCity] = useState(city);

  const normalizedSelectedCity = normalizeText(selectedCity);

  const uniqueCities = [...new Set(mapData.map(item => item.city.toLowerCase()))].sort((a, b) => a.localeCompare(b, 'en', {sensitivity: 'base'}));

  useEffect(() => {
    const handleRouteChange = url => {
      const params = new URLSearchParams(url.split('?')[1]);
      const newCity = params.get('city');
      setSelectedCity(newCity);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  return (
    <div
      className={classNames('bg-[#F8F6F7] lg:py-[112px] py-6 flex flex-col justify-center', {
        'max-lg:hidden': active !== null,
      })}>
      <div className="flex flex-col mx-auto">
        <h2 className="text-[32px] font-boldie leading-[44px] tracking-[0.64px] text-primary mb-6">{translate('Cities')}</h2>
        <div className="grid xl:grid-cols-5 lg:grid-cols-3 grid-cols-2 lg:gap-x-[21px] lg:gap-y-5 gap-y-3 gap-x-3">
          {uniqueCities.map((uniqCity, index) => {
            // find the corresponding number plate for the current city
            const cityData = mapData.find(item => item.city.toLowerCase() === uniqCity);
            const numberPlate = cityData ? cityData.numberPlate : '';

            return (
              <Link
                href={normalizedSelectedCity === normalizeText(uniqCity) ? '' : `?city=${encodeURIComponent(normalizeText(uniqCity))}`}
                shallow
                key={index}>
                <div
                  key={index}
                  className={classNames('lg:w-[272px] w-[166px] py-2 px-4 border-[0.5px] border-[#E5E5E5] flex items-center cursor-pointer', {
                    'bg-primary': normalizedSelectedCity === normalizeText(uniqCity),
                    'bg-white': normalizedSelectedCity !== normalizeText(uniqCity),
                  })}>
                  <p className="text-dustygray lg:text-[20px] text-[14px] lg:leading-7 leading-5 lg:tracking-[0.4px] tracking-[0.28px]">
                    {numberPlate}
                  </p>
                  <p
                    className={classNames(
                      'text-primary lg:text-[20px] text-[14px] font-boldie lg:ml-3 ml-2 capitalize lg:leading-7 leading-5 lg:tracking-[0.4px] tracking-[0.28px]',
                      {
                        'text-white': normalizedSelectedCity === normalizeText(uniqCity),
                      },
                    )}>
                    {uniqCity}
                  </p>
                  <i className="icon text-shape-right lg:text-[12px] text-[8px] text-active ml-auto" />
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ListOfCities;
